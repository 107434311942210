<template>
  <div class="page">
    <div class="page-left">
      <div v-if="isCollapse" class="collapse">
        <div class="top" @click="isCollapse = !isCollapse">
          <span>展开</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="bot">
          <i class="el-icon-caret-top" @click="pre"></i>
          <i class="el-icon-refresh-right" @click="refresh"></i>
          <i class="el-icon-caret-bottom" @click="next"></i>
        </div>
      </div>
      <div v-if="!isCollapse" class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened>
            <el-menu-item index="/smartelderlycare/runkanban" @click="saveNavState('/smartelderlycare/runkanban')" v-if="show('管理看板')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">管理看板</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartelderlycare/smarthousepatrol" @click="saveNavState('/smartelderlycare/smarthousepatrol')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">智慧巡房</span>
              </template>
            </el-menu-item>
            <el-submenu index="rwgl" v-if="show('任务管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">任务管理</span>
              </template>
              <el-menu-item index="/smartelderlycare/retirement/taskkanban" @click="saveNavState('/smartelderlycare/retirement/taskkanban')">
                <template slot="title">
                  <span class="mrgin-left">任务看板</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/taskrun/handoverrecord" @click="saveNavState('/smartelderlycare/taskrun/handoverrecord')">
                <template slot="title">
                  <span class="mrgin-left">交接记录</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/smartelderlycare/monitoring" @click="saveNavState('/smartelderlycare/monitoring')" v-if="show('实时监护')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">实时监护</span>
              </template>
            </el-menu-item>
            <el-submenu index="jkda" v-if="show('健康档案')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">健康档案</span>
              </template>
              <el-menu-item index="/smartelderlycare/healthrecords/basicarchives" @click="saveNavState('/smartelderlycare/healthrecords/basicarchives')">
                <template slot="title">
                  <span class="mrgin-left">基础档案</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/healthrecords/old" @click="saveNavState('/smartelderlycare/healthrecords/old')">
                <template slot="title">
                  <span class="mrgin-left">长者专档</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="rygl" v-if="show('入出院管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">入出院管理</span>
              </template>
              <el-menu-item index="/smartelderlycare/accessrun/admissionregistration" @click="saveNavState('/smartelderlycare/accessrun/admissionregistration')">
                <template slot="title">
                  <span class="mrgin-left">入院登记</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/accessrun/dischargeregistration" @click="saveNavState('/smartelderlycare/accessrun/dischargeregistration')">
                <template slot="title">
                  <span class="mrgin-left">出院登记</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/accessrun/appointmentregistration" @click="saveNavState('/smartelderlycare/accessrun/appointmentregistration')">
                <template slot="title">
                  <span class="mrgin-left">预约登记</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="zygl" v-if="show('在院管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">在院管理</span>
              </template>
              <el-menu-item index="/smartelderlycare/inhospitalrun/oldrun" @click="saveNavState('/smartelderlycare/inhospitalrun/oldrun')">
                <template slot="title">
                  <span class="mrgin-left">老年管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/inhospitalrun/nursinggrade" @click="saveNavState('/smartelderlycare/inhospitalrun/nursinggrade')">
                <template slot="title">
                  <span class="mrgin-left">护理等级变更</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="ylgl" v-if="show('养老管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">养老管理</span>
              </template>
              <!-- <el-menu-item index="/smartelderlycare/retirement" @click="saveNavState('/smartelderlycare/retirement')">
                <template slot="title">
                  <span class="mrgin-left">任务管理</span>
                </template>
              </el-menu-item> -->
              <el-menu-item index="/smartelderlycare/elderlyCare/nursingrun" @click="saveNavState('/smartelderlycare/elderlyCare/nursingrun')">
                <template slot="title">
                  <span class="mrgin-left">护理管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/elderlyCare/outrun" @click="saveNavState('/smartelderlycare/elderlyCare/outrun')">
                <template slot="title">
                  <span class="mrgin-left">外出管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/elderlyCare/houseactivities" @click="saveNavState('/smartelderlycare/elderlyCare/houseactivities')">
                <template slot="title">
                  <span class="mrgin-left">院内活动</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/elderlyCare/laundryservice" @click="saveNavState('/smartelderlycare/elderlyCare/laundryservice')">
                <template slot="title">
                  <span class="mrgin-left">洗衣服务</span>
                </template>
              </el-menu-item>
              <!-- <el-menu-item index="/smartelderlycare/medicalcarerun" @click="saveNavState('/smartelderlycare/medicalcarerun')">
                <template slot="title">
                  <span class="mrgin-left">医护管理</span>
                </template>
              </el-menu-item> -->
            </el-submenu>
            <el-submenu index="yhgl" v-if="show('医护管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">医护管理</span>
              </template>
              <el-menu-item index="/smartelderlycare/managedcare/entrustedmedication" @click="saveNavState('/smartelderlycare/managedcare/entrustedmedication')" v-if="showYhgl('委托用药')">
                <template slot="title">
                  <span class="mrgin-left">委托用药</span>
                </template>
              </el-menu-item>

              <el-menu-item index="/smartelderlycare/managedcare/roomrun" @click="saveNavState('/smartelderlycare/managedcare/roomrun')" v-if="showYhgl('查房管理')">
                <template slot="title">
                  <span class="mrgin-left">查房管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="hlfw" v-if="show('护理服务')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">护理服务</span>
              </template>
              <el-menu-item index="/smartelderlycare/nursing" @click="saveNavState('/smartelderlycare/nursing')">
                <template slot="title">
                  <span class="mrgin-left">护理计划</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/medications" @click="saveNavState('/smartelderlycare/medications')">
                <template slot="title">
                  <span class="mrgin-left">用药计划</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/recovery" @click="saveNavState('/smartelderlycare/recovery')">
                <template slot="title">
                  <span class="mrgin-left">康复计划</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="jtcw" v-if="show('家庭床位')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">家庭床位</span>
              </template>
              <el-menu-item index="/smartelderlycare/monitorkanban" @click="saveNavState('/smartelderlycare/monitorkanban')">
                <template slot="title">
                  <span class="mrgin-left">监测看板</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/service" @click="saveNavState('/service')">
                <template slot="title">
                  <span class="mrgin-left">上门服务</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/healthwarning" @click="saveNavState('/healthwarning')" v-if="show('健康预警')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">健康预警</span>
              </template>
            </el-menu-item>
            <el-submenu index="jkgy" v-if="show('健康干预')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">健康干预</span>
              </template>
              <el-menu-item index="/emr" @click="saveNavState('/emr')">
                <template slot="title">
                  <span class="mrgin-left">电子病历</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/doctoradvice" @click="saveNavState('/doctoradvice')">
                <template slot="title">
                  <span class="mrgin-left">医嘱</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/medication" @click="saveNavState('/smartelderlycare/medication')">
                <template slot="title">
                  <span class="mrgin-left">用药</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/exercisediet" @click="saveNavState('/exercisediet')">
                <template slot="title">
                  <span class="mrgin-left">运动与饮食建议</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/patientmanage" @click="saveNavState('/patientmanage')">
                <template slot="title">
                  <span class="mrgin-left">患者管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/smartelderlycare/reviewRun" @click="saveNavState('/smartelderlycare/reviewRun')" v-if="show('评估管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">评估管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartelderlycare/devicetube" @click="saveNavState('/smartelderlycare/devicetube')" v-if="show('设备管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">设备管理</span>
              </template>
            </el-menu-item>
            <!-- v-if="show('设备安装位置')" -->
            <el-menu-item index="/smartelderlycare/deviceposition" @click="saveNavState('/smartelderlycare/deviceposition')" >
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">设备安装位置</span>
              </template>
            </el-menu-item>
            <el-submenu index="jcsz" v-if="show('基础设置')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">基础设置</span>
              </template>
              <el-menu-item index="/smartelderlycare/basicset/userrun" @click="saveNavState('/smartelderlycare/basicset/userrun')">
                <template slot="title">
                  <span class="mrgin-left">用户管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/basicset/organrun" @click="saveNavState('/smartelderlycare/basicset/organrun')">
                <template slot="title">
                  <span class="mrgin-left">机构管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/basicset/nurseset/run" @click="saveNavState('/smartelderlycare/basicset/nurseset/run')">
                <template slot="title">
                  <span class="mrgin-left">护理设置</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/basicset/bed" @click="saveNavState('/smartelderlycare/basicset/bed')">
                <template slot="title">
                  <span class="mrgin-left">床位管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/basicset/carefulset" @click="saveNavState('/smartelderlycare/basicset/carefulset')">
                <template slot="title">
                  <span class="mrgin-left">审批设置</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/basicset/form" @click="saveNavState('/smartelderlycare/basicset/form')">
                <template slot="title">
                  <span class="mrgin-left">表单组件</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/basicset/data" @click="saveNavState('/smartelderlycare/basicset/data')">
                <template slot="title">
                  <span class="mrgin-left">数据字典</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/basicset/name" @click="saveNavState('/smartelderlycare/basicset/name')">
                <template slot="title">
                  <span class="mrgin-left">数据字典</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="wyszgl" v-if="show('运营管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text font">运营管理</span>
              </template>
              <el-menu-item index="/smartelderlycare/financeIncome/business" @click="saveNavState('/smartelderlycare/financeIncome/business')">
                <template slot="title">
                  <span class="mrgin-left">业务收支管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/financeIncome/operate" @click="saveNavState('/smartelderlycare/financeIncome/operate')">
                <template slot="title">
                  <span class="mrgin-left">运作收支管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="hjgl" v-if="show('后勤管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">后勤管理</span>
              </template>
              <el-menu-item index="/smartelderlycare/logisticsrun/purchasingrequisition" @click="saveNavState('/smartelderlycare/logisticsrun/purchasingrequisition')">
                <template slot="title">
                  <span class="mrgin-left">采购申请</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="scxs" v-if="show('市场销售')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text font">市场销售</span>
              </template>
              <el-menu-item index="/smartelderlycare/marketsales/potentialcustomers" @click="saveNavState('/smartelderlycare/marketsales/potentialcustomers')">
                <template slot="title">
                  <span class="mrgin-left">潜在客户</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/marketsales/accessrun" @click="saveNavState('/smartelderlycare/marketsales/accessrun')">
                <template slot="title">
                  <span class="mrgin-left">出入院管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/marketsales/relationshiprun" @click="saveNavState('/smartelderlycare/marketsales/relationshiprun')">
                <template slot="title">
                  <span class="mrgin-left">关系管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/marketsales/servequality" @click="saveNavState('/smartelderlycare/marketsales/servequality')">
                <template slot="title">
                  <span class="mrgin-left">服务质量</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="rsxzgl" v-if="show('人事行政管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text font">人事行政管理</span>
              </template>
              <el-menu-item index="/smartelderlycare/personnelmattersrun/personnelrun" @click="saveNavState('/smartelderlycare/personnelmattersrun/personnelrun')">
                <template slot="title">
                  <span class="mrgin-left">人事管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/payroll" @click="saveNavState('/smartelderlycare/payroll')">
                <template slot="title">
                  <span class="mrgin-left">薪资管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="spgl" v-if="show('审批管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">审批管理</span>
              </template>
              <el-menu-item index="/smartelderlycare/examineapproverun/bed" @click="saveNavState('/smartelderlycare/examineapproverun/bed')">
                <template slot="title">
                  <span class="mrgin-left">床位更换申请审批</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/examineapproverun/goout" @click="saveNavState('/smartelderlycare/examineapproverun/goout')">
                <template slot="title">
                  <span class="mrgin-left">外出申请审批</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartelderlycare/examineapproverun/nurse" @click="saveNavState('/smartelderlycare/examineapproverun/nurse')">
                <template slot="title">
                  <span class="mrgin-left">护理等级变更申请审批</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/smartelderlycare/dictionary" @click="saveNavState('/smartelderlycare/dictionary')" v-if="show('护理字典维护')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">护理字典维护</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404" v-if="show('床位管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">床位管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404" v-if="show('护工管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">护工管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404" v-if="show('机构管理')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">机构管理</span>
              </template>
            </el-menu-item>

          </el-menu>
        </div>
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/smartelderlycare',
      tab: [],
      tabs: []
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    this.tab = this.loginInfo.permissions.filter(item => item.parentId === 147)
    // console.log('父级：', this.tab)
    this.tabs = this.loginInfo.permissions.filter(item => item.parentId === 185)
    console.log('医护管理子级：', this.tabs)
    this.activePath = window.sessionStorage.getItem('smartelderlycarePath') || '/smartelderlycare'
  },
  methods: {
    show (val) {
      if (this.tab.length < 1) return false
      return this.tab.filter(item => item.name === val).length > 0
    },
    showYhgl (val) {
      if (this.tabs.length < 1) return false
      return this.tabs.filter(item => item.name === val).length > 0
    },
    saveNavState (path) {
      window.sessionStorage.setItem('smartelderlycarePath', path)
      window.sessionStorage.setItem('headTabActivePath', path)
      this.activePath = path
    },
    pre () { },
    next () { },
    refresh () { }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    overflow: hidden;
    width: 120px;
    height: 100%;
    background-color: #006EFF;

    .collapse {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      color: #fff;
      height: 100%;
      padding-top: 100px;

      .top {
        height: 30%;
        width: 40px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        cursor: pointer;

        i {
          font-size: 20px;
        }
      }

      .bot {
        height: 20%;
        width: 40px;
        margin-top: 80px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .menu {
      position: relative;
      width: 100%;
      height: 100%;

      .uncollapse {
        position: absolute;
        bottom: 0;
        writing-mode: tb-rl;
        text-align: center;
        line-height: 40px;
        margin-left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 10px);

        &::-webkit-scrollbar {
          width: 0;
        }

        i {
          color: #fff;
        }

        .el-menu {
          border: none;
          background-color: #006EFF !important;
          color: #fff !important;
        }

        /deep/.el-menu-item {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 0 !important;
          background-color: #006EFF !important;
          font-size: 16px;
          color: #fff !important;
        }

        .font {
          color: #fff;
          font-size: 16px;
        }

        /deep/.el-menu-item.is-active {
          color: #25A0FF !important;
        }

        /deep/.el-submenu__title {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 1px !important;
          padding-right: 2px !important;
          background-color: #006EFF !important;
          color: #17212B !important;
        }
        /deep/.el-icon-arrow-down:before {
          content: '';
        }
        .menu-text {
          width: calc(100% - 40px);
          line-height: normal;
          white-space: pre-wrap;
        }

        .submenu-text {
          width: calc(100% - 30px);
          line-height: normal;
          white-space: pre-wrap;
        }

        .mrgin-left {
          display: block;
          width: 80px;
          margin-left: 30px;
          line-height: normal;
          white-space: pre-wrap;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 107px);
    height: 100%;
    background-color: #F0F0F0;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
